import './App.css';

import prj_installeteur_stadt from './media/project-installateur-stadt.png';
import prj_callback4web from './media/project-calback4web.png';
import prj_ico from './media/project-ico.gif';
import prj_trocknung from './media/project-trocknung.png';
import prj_nagelstudio from './media/project-nagelstudio.png';
import prj_printmedia from './media/project-printmedia.png';

import { ReactTyped } from 'react-typed';
import { VideoContr } from './components/VideoContr';
import { GrLinkedin } from "react-icons/gr";

function App() {

	
	return (
	<>
		<header className="flex-row header">
			<span></span>
			<code>&lt;header&gt;</code>
			<a href="https://www.linkedin.com/in/kolobox/" target='_blank' rel='noreferrer'><GrLinkedin /></a> 
		</header>
		
		{/*	#################################################
										ABOUT + VIDEO
				################################################# */}

		<div className="flex-row">
			<section className="flex-col section">
				<code>&lt;section&gt;</code>
				<h2>about</h2>
				<p>
					<ReactTyped
						strings={[
							"Viel kommen/Mist/ ",
							"Willkommen auf der ewigen Baustelle!",
							"Die Schätzungen über die Fertigstellung:",
							"variieren von...", 
							"\"mache's in 2 Stunden...", 
							"im Laufe des Jahres\"...", 
							"bis \"fast fertig\".",
							"Vielen Dank fürs Vorbeischauen!"
						]}
						typeSpeed={100}
						backSpeed={10}
						loop
					 />
				</p>
				<h1>kolobox</h1>
				<p className="font-light">ursprünglich war ich als Mediengestalter für Printmedien tätig, bis mich die Webentwicklung in ihren Bann gezogen hat. Ich liebe die kreative Herausforderung meiner Arbeit. Routine mag ich nicht, aber sie ist immer Teil der Arbeit.</p>
			</section>

			<figure className="flex-col video-container">
				<code>&lt;figure&gt;</code>
				<VideoContr fill={'#cccccc'}/>
			</figure>
		</div>


		{/*	#################################################
										WEB APP PROJECTS
				################################################# */}

		<section className="flex-col section">
			<code>&lt;section&gt;</code>
			<h2>Web app projects</h2>
			<div><br /></div>


			<div className="flex-row projects">

				<div className="project">
					<div className="stickyNoteWrapper">
						<div className='stickyNoteBorder'>
						</div>
						<ul className='stickyNoteText rotate-8'>
								<li>Responsive Webdesign</li>
								<li>html, css</li>
								<li>php</li>
								<li>javascript</li>
								<li>MySQL</li>
								<li>1260 Städte</li>
						</ul>
					</div>

					<a href='https://ch-ch-sd.kolobox.de/' target="_blank" rel='noreferrer'><img className="rotate_4" src={prj_installeteur_stadt} alt=""/></a>
				</div>

				<div className="project">
					<div className="stickyNoteWrapper">
						<div className='stickyNoteBorder'>
						</div>
						<ul className='stickyNoteText'>
							<li>Responsive Webdesign</li>
								<li>html, css</li>
								<li>javaScript</li>
								<li>Adobe Flash 2015</li>
								<li>-- --------- </li>
								<li>Film/Trailer:</li>
								<li>&#58;&#41;_Regie, Casting, Kamera,Ton,Montage</li>
							</ul>	
					</div>

					<a href='https://cb4web.kolobox.de/' target="_blank" rel='noreferrer'><img className="rotate-8" src={prj_callback4web} alt="" /></a>
				</div>

				<div className="project">
					<div className="stickyNoteWrapper">
						<div className='stickyNoteBorder stickyShadowLeft'>
						</div>
						<ul className='stickyNoteText'>
							<li>Responsive Webdesign</li>
							<li>frontend only</li>
							<li>html, Bootstrap</li>
							<li>JavaScript</li>
						</ul>
					</div>	

					<a href='https://ico.kolobox.de/' target="_blank" rel='noreferrer'><img className="rotate-2" src={prj_ico} alt=""/></a>
				</div>

				<div className="project">
					<div className="stickyNoteWrapper">
						<div className='stickyNoteBorder'>
						</div>
						<ul className='stickyNoteText'>
								<li>Responsive Webdesign</li>
								<li>html, css</li>
								<li>php</li>
								<li>javascript</li>
						</ul>
					</div>	

					<a href='https://trock24.de/' target="_blank" rel='noreferrer'><img className='rotate-8' src={prj_trocknung} alt=""/></a>
				</div>

				<div className="project">
					<div className="stickyNoteWrapper">
						<div className='stickyNoteBorder'>
						</div>
						<ul className='stickyNoteText rotate-8'>
								<li>Responsive Webdesign</li>
								<li>html, css</li>
								<li>php</li>
								<li>MySQL</li>
						</ul>
					</div>

					<a href='https://freenails.kolobox.de/' target="_blank" rel='noreferrer'><img className="rotate-2" src={prj_nagelstudio} alt=""/></a>
				</div>

				<div className="project">
					<div className="stickyNoteWrapper">
						<div className='stickyNoteBorder'>
						</div>
						<ul className='stickyNoteText'>
								<li>Postern, Flyern, Broschüren, Originalverpackungen</li>
								<li>Screendesigns</li>
								<li>--- ---- --------</li>
								<li>Photoshop</li>
								<li>Illustraitor</li>
								<li>Dreamweaver</li>
						</ul>
					</div>

					<a href='https://kolobox.de/pdf/portfolio-2000-2013.pdf' target="_blank" rel='noreferrer'><img className="rotate_4" src={prj_printmedia} alt=""/></a>
				</div>

			</div>
		</section>


		{/*	#################################################
									TOOLS + EXPERIMENTS
				################################################# */}

		<div className="flex-row">
			<section className="flex-col section">
					<code>&lt;section&gt;</code>
					<h2>tools</h2>
					<h4 className="font-light">Languages/Frameworks/Libraries</h4>
					<ul>
						<li className="textBorder">JavaScript</li>
						<li className="textBorder">PHP</li>
						<li className="textBorder">MySQL</li>
						<li className="textBorder">React</li>
					</ul>
					<p>Englisch : Basiskenntnisse. <br />Deutsch : gute Sprachkenntnisse = B2.</p>
			</section>

			<section className="flex-col section">
			<code>&lt;section&gt;</code>
					<h2>experiments</h2>
					<h4 className="font-light">Languages/Frameworks/Libraries</h4>
					<ul>
						<li className="textBorder">Docker</li>
						<li className="textBorder">Node.js</li>
					</ul>
					<p сlass="font-light">ohne viel praktische Erfahrung</p>
			</section>
		</div>


		<footer className="flex-row footer"><code>&lt;footer&gt;</code></footer>




		{/* 	? noopener noreferrer
				<a href="https://reactjs.org" target="_blank" rel="noopener noreferrer"> 

				s.	https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
		*/}
	</>
	);
}

export default App;
