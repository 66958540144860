import { useEffect, useRef, useState } from "react";
import { VscStopCircle, VscPlayCircle, VscMute, VscUnmute } from "react-icons/vsc";

import clip from '../media/03-600-portfolio-03.mp4';
import './videoContr.css'


export const VideoContr = () => {

	const videoRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [seekValue, setSeekValue] = useState(0);
	const [isMuted, setIsMuted] = useState(false);
	const [volumeValue, setVolumeValue] = useState(25);

	const [autoplayMsg, setAutoplayMsg] = useState(null);
 
	useEffect(() => {
		const currentVideo = videoRef.current;

		const handleTimeUpdate = () => {
			updateSeekValue();
		};

		/* установить соответствующий значение статуса воспроизведения isPlaying 
			для случаев когда автовоспроизведение браузером разрешено или заблокировано */

		const isAutoplayAllowed = async (currentVideo) => {
			try {
				await currentVideo.play();
				// console.log("Автовоспроизведение разрешено");
				setIsPlaying(true);
			} catch (error) {
				// console.log("Автовоспроизведение заблокировано");
				setIsPlaying(false);
				setAutoplayMsg('Autoplay blocked - press play');
			}
		 };
	
		 // Вызываем проверку при загрузке компонента
		 if (currentVideo) {
			isAutoplayAllowed(currentVideo);
		 }

		if(currentVideo) {
			currentVideo.addEventListener('timeupdate', handleTimeUpdate);
				/* timeupdate - событие на элементе <video> происходит обычно несколько раз в секунду
					и позволяет отслеживать изменения времени воспроизведения.
					при событии вызываемая handleTimeUpdate обновляет состояние компонента, 
					связанное с изменением времени воспроизведения (например, обновляет значение ползунка). */
		}

		const updateSeekValue = () => {
			if (currentVideo && isFinite(currentVideo.duration)) {
				const newValue = (currentVideo.currentTime / currentVideo.duration) * 100 || 0;
				setSeekValue(newValue);
			}
		};

		// Удаление обработчиков при размонтировании компонента
		return () => {
			if (currentVideo) {
				currentVideo.removeEventListener('timeupdate', handleTimeUpdate);
			}
		};
	}, [setIsPlaying]);  // [videoRef, setIsPlaying, setSeekValue]) пока работает без указания зависимостей
 
	const handlePlayPause = () => {
		const currentVideo = videoRef.current;
		if (isPlaying) {
			currentVideo.pause();
		} else {
			currentVideo.play().catch((error) => console.error("Play failed:", error));
		}
		setIsPlaying(!isPlaying);
		setAutoplayMsg(null); // при любом play/pause убрать сообщение о блокировке Автовоспроизведения 
	};
 
	const handleSeekBar = (e) => {
		const currentVideo = videoRef.current;
		if (currentVideo && isFinite(currentVideo.duration)) {
			const seekTo = (e.target.value / 100) * currentVideo.duration;
			currentVideo.currentTime = seekTo;
		}
	};
 
	const handleMute = () => {
	  setIsMuted((prevMuted) => !prevMuted);
	};
 
	const handleVolumeChange = (e) => {
	  const currentVideo = videoRef.current;
	  setIsMuted(false);
	  setVolumeValue(e.target.value);
	  currentVideo.volume = e.target.value / 100;
	};
 
	const handleUserInteraction = () => {
	  const currentVideo = videoRef.current;
		// console.log('isPlaying:', isPlaying)
		// console.log('currentVideo:', currentVideo)

		if (currentVideo) {
			handlePlayPause();
		}
	};


	return (
		<>
				<pre className="autoplayMsg">{autoplayMsg}</pre>
				<video onClick={handleUserInteraction}
					ref={videoRef}
					className="video"
					controls={false}
					muted={isMuted}
					autoPlay
					preload="auto"
					loop
				>
					<source src={clip} type="video/mp4" />
					<p>
							Your browser doesn't support HTML video. Here is a
							<a href={clip} download="kolobox-de.mp4">link to the video</a> instead.
					</p>
				
				</video>
				
				<div className="custom-controls">
					<input
						type="range"
						onChange={handleSeekBar}
						value={seekValue}
						max="100"
						step="0.1"
					/>
					<button onClick={handlePlayPause}>
						{isPlaying ? <VscStopCircle /> : <VscPlayCircle />}
					</button>
					<button onClick={handleMute}>
						{isMuted ? <VscUnmute /> : <VscMute />}
					</button>
					<input
						type="range"
						onChange={handleVolumeChange}
						value={volumeValue}
						max="100"
						step="1"
					/>
				</div>

		</>
	)

}